<template>
	<div class="home">
		<Header></Header>
		<div class="wrapper home-wrapper">
			<div class="search shadow">
				<span class="title">筛选：</span>
				<el-select v-model="fleetValue" placeholder="请选择车队">
					<el-option v-for="item in fleetList" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
				<el-input class="search-input" @keyup.enter.native="search()" v-model="CarIdValue" placeholder="请输入车牌号或车架号进行模糊搜索"></el-input>
				<el-button type="primary" @click="search()">搜索</el-button>
				<router-link v-if="stepInsurance" target="_blank" :to="{name:'addTruck'}" style="margin-left: 10px;">
					<el-button type="primary">添加车辆</el-button>
				</router-link>
			</div>
			<newFleetList></newFleetList>
			<div class="remind-roll">
				<div class="main-title">
					待保养车辆
					<router-link :to="{name:'remind',query:{activeName:1}}">
						<span>更多>></span>
					</router-link>
				</div>
				<remindList :remindList="remindList" type="0"></remindList>
				<div class="main-title">
					待缴保险车辆
					<router-link :to="{name:'remind',query:{activeName:2}}">
						<span>更多>></span>
					</router-link>
				</div>
				<remindList :remindList="remindList1" type="1"></remindList>
				<div class="main-title">
					待年审车辆
					<router-link :to="{name:'remind',query:{activeName:3}}">
						<span>更多>></span>
					</router-link>
				</div>
				<remindList :remindList="remindList2" type="2"></remindList>
                <div class="main-title">
                    预约工单
                    <router-link :to="{name:'remind',query:{activeName:4}}">
                        <span>更多>></span>
                    </router-link>
                </div>
                <remindList :remindList="remindList3" type="3"></remindList>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import http from '../../http.js'
	import Header from "../../components/header.vue"
	import Footer from "../../components/footer.vue"
	import newFleetList from "./newFleetList.vue"
	import remindList from "./remindList.vue"
	export default {
		name: 'Home',
		components: {
			Header,
			Footer,
			newFleetList,
			remindList
		},
		data() {
			return {
				scrollTop: false,
				scrollTime: undefined,
				remindList: [],
				remindList1: [],
				remindList2: [],
                remindList3: [],
				fleetList: [],
				fleetValue: '',
				CarIdValue: '',
				stepInsurance: false,
			}
		},
		created() {
			let roles = localStorage.getItem('roles').split(',')
			if(roles.indexOf('mplan-rent') > -1){
				this.stepInsurance = true
			}
			this.init()
		},
		methods:{
			init(){
				this.handleMaintain()
				this.handleInsurance()
				this.handleInspection()
                this.handleAppointment()
				this.getFleetList()
			},
			getFleetList() {
				http.fetchGet('/api/admin/fleet/list', {
					page: 1,
					pagesize: 1000
				}).then((res) => {
					if (res.data.code == 0) {
						this.fleetList = res.data.data.list
					}
				})
			},
			search(){
				this.$router.push("/carId?keyword="+this.CarIdValue+"&fleetId="+this.fleetValue)
			},
			handleMaintain(){
				http.fetchGet('/api/v2/admin/car-check/maintain/list', { page: 1, size: 100 }).then((res) => {
					if (res.data.code == 0) {
						let list = res.data.data.list
						this.remindList = []
						list.forEach(item => {
							this.remindList.push({
								id: item.id,
								vin: item.vin,
								number: item.number,
								tipsContent: '保养',
								tips: '还剩' + (item.next_mileage - item.mileage) + '公里',
								unit: '',
								read: item.maintain_tip == 1
							})
						})
					}
				})
			},
			handleInsurance(){
				http.fetchGet('/api/v2/admin/car-check/insurance/list', { page: 1, size: 100 }).then((res) => {
					if (res.data.code == 0) {
						let list = res.data.data.list
						this.remindList1 = []
						list.forEach(item => {
							this.remindList1.push({
								id: item.id,
								vin: item.vin,
								number: item.number,
								tipsContent: '缴保险时间',
								tips: item.insuranceDate,
								unit: '之前',
								read: item.insurance_tip == 1
							})
						})
						console.log(this.remindList1)
					}
				})
			},
			handleInspection(){
				http.fetchGet('/api/v2/admin/car-check/inspection/list', { page: 1, size: 100 }).then((res) => {
					if (res.data.code == 0) {
						let list = res.data.data.list
						this.remindList2 = []
						list.forEach(item => {
							this.remindList2.push({
								id: item.id,
								vin: item.vin,
								number: item.number,
								tipsContent: '年审时间',
								tips: item.inspectionDate,
								unit: '之前',
								read: item.inspection_tip == 1
							})
						})
					}
				})
			},
            handleAppointment(){
                http.fetchGet('/api/v2/admin/car-check/appointment/list', { page: 1, size: 100 }).then((res) => {
                    if (res.data.code == 0) {
                        let list = res.data.data.list
                        this.remindList3 = []
                        list.forEach(item => {
                            this.remindList3.push({
                                id: item.id,
                                vin: item.store,
                                number: item.number,
                                phone: item.phone,
                                contact: item.contact,
                                store_phone: item.store_phone,
                                store_contact: item.store_contact,
                                appointment_time: item.appointment_time,
                                tipsContent: '预约工单',
                                tips: item.appointmentTime,
                                unit: '',
                                read: item.appointment_tip == 1
                            })
                        })
                    }
                })
            },
		}

	}
</script>
<style lang="scss">
	@import "~@/assets/css/home.scss"
	// .home {}
</style>
