import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'introduce',
		component: () => import( /* webpackChunkName: "introduce" */ '../views/introduce/introduce.vue')
	},
	{
		path: '/Home',
		name: 'Home',
		component: Home
	},
	{
		path: '/carId',
		name: 'carId',
		component: () => import( /* webpackChunkName: "carId" */ '../views/truck/carId.vue')
	},
	{
		path: '/typeSelect',
		name: 'typeSelect',
		component: () => import( /* webpackChunkName: "typeSelect" */ '../views/form/typeSelect.vue')
	},
	{
		path: '/firstForm',
		name: 'firstForm',
		component: () => import( /* webpackChunkName: "firstForm" */ '../views/form/firstForm.vue')
	},
	{
		path: '/generalForm',
		name: 'generalForm',
		component: () => import( /* webpackChunkName: "generalForm" */ '../views/form/generalForm.vue')
	},
	{
		path: '/maintainList',
		name: 'maintainList',
		component: () => import( /* webpackChunkName: "maintainList" */ '../views/me/maintainList.vue')
	},
	{
		path: '/remind',
		name: 'remind',
		component: () => import( /* webpackChunkName: "remind" */ '../views/me/remind.vue')
	},
	{
		path: '/remindDetail',
		name: 'remindDetail',
		component: () => import( /* webpackChunkName: "remindDetail" */ '../views/me/remindDetail.vue')
	},
	{
		path: '/articleDetail',
		name: 'articleDetail',
		component: () => import( /* webpackChunkName: "articleDetail" */ '../views/article/articleDetail.vue')
	},
	{
		path: '/addTruck',
		name: 'addTruck',
		component: () => import( /* webpackChunkName: "addTruck" */ '../views/truck/addTruck.vue')
	}
]

const router = new VueRouter({
	routes
})

export default router
