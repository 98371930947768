<template>
	<div class="no-maintain">
		<div id="box" class="no-maintain-list shadow">
			<ul :class="{scrolltop:scrollTop==true}" @mouseenter="stopScroll()" @mouseleave="startScroll()">
				<li v-for="(item) in remindList" :key="item.id" @click="remindDetail(item)">
					<div class="car-info">
						<div class="car-number">
							<i class="el-icon-truck"></i>
							<span>{{item.vin}}</span>
						</div>
						<span class="car-id">{{item.number}}</span>
					</div>
					<div class="bottom">
						<div class="tips">
							{{item.tipsContent}}：<span>{{item.tips}}</span>{{item.unit}}
						</div>
						<el-tag type="success" size="mini" v-if="item.read == 1">已提醒</el-tag>
						<el-tag type="danger" size="mini" v-if="item.read == 0">未提醒</el-tag>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				scrollTop: false,
				scrollTime: undefined
			}
		},
		props: {
			'remindList': Array,
			'type': Number
		},
		methods: {
			remindDetail(item){
				this.$router.push("/remindDetail?id="+item.id+"&type="+this.type)
			},
			stopScroll() {
				clearInterval(this.scrollTime);
			},
			startScroll() {
				this.scroll();
			},
			scroll() {
				this.scrollTime = setInterval(() => {
					this.scrollTop = true;
					setTimeout(() => {
						this.remindList.push(this.remindList[0]); // 将数组的第一个元素添加到数组的
						this.remindList.shift(); //删除数组的第一个元素
						this.scrollTop = false; // margin-top 为0 的时候取消过渡动画，实现无缝滚动
					}, 500)
				}, 3000);
			},
			
		},
		created() {
			this.scroll();
		}
	}
</script>

<style>
</style>
