<template>
	<div class="header">
		<div class="wrapper">
			<div class="nav">
				<div class="logo">
					<router-link to='/Home'>
						<img src="../assets/img/logo.png" />
					</router-link>
				</div>
				<ul class="nav-list">
					<router-link v-if="name" to='/Home'>
						<li>首页</li>
					</router-link>
					<router-link v-if="!name" to='/'>
						<li>首页</li>
					</router-link>
					<router-link to='/articleDetail'>
						<li>活动介绍</li>
					</router-link>
				</ul>
			</div>
			<div class="login-info">
				<el-dropdown v-if="name" class="login-after" @command="handleCommand">
					<span class="el-dropdown-link">
						<el-badge :value="tips" :max="99">{{name}}</el-badge>
						<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<!-- <el-dropdown-item command="1">检测列表</el-dropdown-item> -->
						<el-dropdown-item command="2">
							<el-badge is-dot>我的提醒</el-badge>
						</el-dropdown-item>
						<el-dropdown-item command="3">
							<el-badge>修改密码</el-badge>
						</el-dropdown-item>
						<el-dropdown-item command="4">
							<el-badge>退出登录</el-badge>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<el-button v-if="!name" size="mini" round class="login-btn" @click="dialogVisible = true">登录</el-button>
			</div>
		</div>
		<!-- 登录弹窗 -->
		<el-dialog title="登录" :visible.sync="dialogVisible" width="400px">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
				<el-form-item prop="user">
					<el-input prefix-icon="el-icon-user" placeholder="请输入账号" v-model="ruleForm.account"></el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input prefix-icon="el-icon-lock" placeholder="请输入密码" type="password"
						v-model="ruleForm.password"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		<el-dialog title="修改密码" :visible.sync="dialogPwdVisible" width="400px">
			<el-form :model="pwdForm" :rules="pwdRules" ref="pwdForm" class="demo-ruleForm">
				<el-form-item prop="oldpassword">
					<el-input prefix-icon="el-icon-user" placeholder="请输入旧密码" type="password" 
						v-model="pwdForm.oldpassword"></el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input prefix-icon="el-icon-lock" placeholder="请输入新密码" type="password"
						v-model="pwdForm.password"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitPwd('pwdForm')">修改密码</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import http from '../http.js'
	export default {
		data() {
			return {
				tips: '',
				dialogVisible: false,
				name: '',
				ruleForm: {
					account: '',
					password: ''
				},
				rules: {
					account: [{
						required: true,
						message: '请输入正确的账号',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入正确的密码',
						trigger: 'blur'
					}]
				},
				dialogPwdVisible: false,
				pwdForm: {
					oldpassword: '',
					password: ''
				},
				pwdRules: {
					oldpassword: [{
						required: true,
						message: '请输入旧密码',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入新密码',
						trigger: 'blur'
					}]
				}
			}
		},
		created() {
			let _name = localStorage.getItem('name')
			if(_name){
				this.name = _name
			}
			if(!this.name){
				this.dialogVisible = false
			}
			this.getTipsNum()
			this.getUserInfo()
		},
		methods: {
			getTipsNum(){
				http.fetchGet('/api/v2/admin/car-check/tips/count').then((res) => {
					if (res.data.code == 0) {
						this.tips = res.data.data
					}
				})
			},
			handleCommand(command) {
				if (command == 1) {
					this.$router.push("/maintainList")
				} else if(command == 2) {
					this.$router.push({
						path: "/remind",
						query: {
							activeName:1
						}
					})
				} else if (command == 3){
					this.dialogPwdVisible = true
				} else {
					this.logout()
				}
			},
			logout(){
				localStorage.removeItem('token')
				localStorage.removeItem('name')
				this.dialogVisible = true
				this.$router.push("/")
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						http.fetchPost('/api/admin/login', this.ruleForm).then((res) => {
							if (res.data.code == 0) {
								localStorage.setItem("name", res.data.data.name)
								localStorage.setItem("token", res.data.data.token)
								this.name = res.data.data.name
								this.dialogVisible = false
								this.getUserInfo(1)
								this.$router.push("/Home")
							} else {
								this.$message.error(res.data.desc)
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			getUserInfo(type){
				http.fetchGet('/api/admin/info').then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						localStorage.setItem("roles", res.data.data.roles.toString())
						if(type==1){
							this.$router.go(0)
						}
					} else {
						this.$message.error(res.data.desc)
						localStorage.clear()
						this.name = ''
					}
				})
			},
			submitPwd(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						http.fetchPost('/api/admin/changepwd', this.pwdForm).then((res) => {
							if (res.data.code == 0) {
								this.dialogPwdVisible = false
								this.$message.success('密码修改成功，请重新登录')
								this.logout()
							} else {
								this.$message.error(res.data.desc)
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
		}
	}
</script>

<style>
</style>
