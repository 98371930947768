<template>
	<div class="new-fleet">
		<div class="main-title">最新加入车辆
			<router-link :to="{name:'carId'}">
				<span>更多>></span>
			</router-link>
		</div>
		<el-table :data="tableData" stripe style="width: 100%" :row-class-name="tableRowClassName">
			<el-table-column label="车牌号" width="170">
				<template slot-scope="scope">
					<span>{{scope.row.number}}</span>
					<img src="../../assets/img/new.png" v-if="scope.row.mplan_new == 1" class="new-icon" />
					<img src="../../assets/img/bao.png" v-if="scope.row.mplancpic == 1" class="bao-icon" />
				</template>
			</el-table-column>
			<el-table-column prop="vin" label="底盘号" width="200"></el-table-column>
			<el-table-column prop="owner" label="车主"></el-table-column>
			<el-table-column prop="phone" label="手机号"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="primary" size="mini" @click="handleSee(scope.row)">查看</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	import http from '../../http.js'
	export default {
		data() {
			return {
				tableData: []
			}
		},
		methods: {
			tableRowClassName({
				row,
				rowIndex
			}) {
				console.log(row,rowIndex)
				if (row.mplan_status == 1) {
					return 'complete';
				}
			},
			handleSee(item) {
				// this.$router.push("/typeSelect?carId=" + id)
				this.$router.push("/carId?keyword=" + item.number)
			},
			GetCarList() {
				http.fetchGet('/api/v2/admin/car-check/car/list', {
					page: 1,
					size: 30
				}).then((res) => {
					if (res.data.code == 0) {
						this.tableData = res.data.data.list
					}
				})
			}
		},
		created() {
			this.GetCarList();
		}
	}
</script>

<style lang="scss">
	.complete {
		color: #59b25e;
	}
</style>
